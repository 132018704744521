<template>
	<div>
		<TablesDefault
			v-model:query="computedQuery"
			v-model:currentFilterValues="currentFilterValues"
			:records="store.instances[instanceLabel].queryData"
			:count="store.activeCount[instanceLabel]"
			:filters="tabs"
			:use-search="useSearch"
			:per-page="store.instances[instanceLabel].perPage"
			:current-page="store.instances[instanceLabel].currentPage"
			:current-sort="store.instances[instanceLabel]?.sort"
			:has-next-page="store.hasNextPage(instanceLabel)"
			:is-loading="store.showLoading(instanceLabel)"
			:has-completed-first-search="store.instances[instanceLabel]?.hasCompletedFirstSearch"
			@previous="store.previousPage(instanceLabel)"
			@next="store.nextPage(instanceLabel)"
			@sort="store.setSort(instanceLabel, {[$event[0]]: $event[1]})"
		>
			<template #default>
				<tr>
					<td class="divide-y divide-jmi-purple4">
						<MoveNotificationsRow v-for="record in store.instances[instanceLabel].queryData" :key="record.id" :layout="layout" :record="record" />
					</td>
				</tr>
			</template>
			<template #empty-default>
				<div class="w-full my-56 mx-auto block">
					<NotAllowed
						v-if="showCaughtUpMessage"
						title="You're all caught up!"
					>
						<template #illustration>
							<IconsRelaxedBird class="w-56 stroke-1 inline-block" />
						</template>
					</NotAllowed>
					<NotAllowed
						v-else
						title="No results found"
					/>
				</div>
			</template>
		</TablesDefault>
	</div>
</template>
<script setup lang="ts">

import {computed, onBeforeMount, PropType, ref, watch} from "vue";
import {useCoos} from "~/stores/coos";

const props = defineProps({
	query: {
		type: String,
		required: false,
		default: ""
	},
	layout: {
		type: String as PropType<"default" | "overview" | "dashboard">,
		required: false,
		default: "default"
	},
	instanceLabel: {
		type: String,
		required: true
	},
	useSearch: {
		type: Boolean,
		required: false,
		default: true
	},
	usePagination: {
		type: Boolean,
		required: false,
		default: true
	}
});

const store = useCoos();

const openRecord = ref(null as Record<string, any> | null);
const emit = defineEmits(['update:query']);

const computedQuery = computed({
	get() {
		return props.query;
	},
	set(value) {
		emit('update:query', value);
	}
});

const open = (record: Record<string, any>) => {
	openRecord.value = record;
};

const close = () => {
	openRecord.value = null;
};

onBeforeMount(() => {
	store.search(props.instanceLabel, props.query);
});

const showCaughtUpMessage = computed<boolean>(() => {
	const hasStatus = !!store.instances[props.instanceLabel].queryFacets["status"];
	const hasErrors = !!store.instances[props.instanceLabel].queryFacets["errors"];
	return (!hasStatus && hasErrors && computedQuery.value?.length === 0);
});

const intl = new Intl.NumberFormat('en-GB');
const refreshCounts = () => {
	return props.useSearch ? {
		status: {
			title: "Status",
			options: [
				{label: `Missing supplier (${store.count(props.instanceLabel, 'errors', 'supplierid')})`, id: 'Missing supplier'},
				{label: `Missing date (${store.count(props.instanceLabel, 'errors', 'movedate')})`, id: 'Missing date'},
				{label: `On hold (${store.count(props.instanceLabel, 'status', 'On Hold')})`, id: 'On Hold'},
				{label: `Unable to send (${store.count(props.instanceLabel, 'status', 'Unable to Send')})`, id: 'Unable to Send'},
				{label: `Sendable (${intl.format(store.count(props.instanceLabel, 'status', 'Sendable'))})`, id: 'Sendable'},
				{label: `Sent (${intl.format(store.count(props.instanceLabel, 'status', 'Sent'))})`, id: 'Sent'},
				{label: `All (${intl.format(store.count(props.instanceLabel, 'status', store.allStatuses))})`, id: undefined},
			]
		}
	} : {};
};
const tabs = ref(refreshCounts());

const currentFilterValues = computed({
	get() {
		if (typeof store.instances[props.instanceLabel].queryFacets.errors !== "undefined") {
			if (store.instances[props.instanceLabel].queryFacets.errors === "supplierid") {
				return {
					status: "Missing supplier"
				};
			} else if (store.instances[props.instanceLabel].queryFacets.errors === "movedate") {
				return {
					status: "Missing date"
				};
			} else {
				throw new Error("Unknown queryFacets.errors: " + store.instances[props.instanceLabel].queryFacets.errors);
			}
		} else {
			return {
				status: store.instances[props.instanceLabel].queryFacets.status ?? undefined
			}
		}
	},
	set(value) {
		if (value.status === "Missing date") {
			store.setFacet(props.instanceLabel, 'status', undefined, false);
			store.setFacet(props.instanceLabel, 'errors', "movedate");
		} else if (value.status === "Missing supplier") {
			store.setFacet(props.instanceLabel, 'status', undefined, false);
			store.setFacet(props.instanceLabel, 'errors', "supplierid");
		} else {
			store.setFacet(props.instanceLabel, 'status', value.status, false);
			store.setFacet(props.instanceLabel, 'errors', undefined);
		}
	}
});

watch(() => props.query, () => {
	store.searchAndResetPage(props.instanceLabel, props.query)
});

watch(() => store.instances[props.instanceLabel].countFetchedAt, () => {
	tabs.value = refreshCounts();
});
watch(() => store.currentRecord, () => {
	if (store.currentRecord) {
		openRecord.value = store.currentRecord;
	}
});

</script>
